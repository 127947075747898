import React, { useState } from 'react';
import Dialog from '../../components/Dialog/Dialog';
import { Checkbox } from '@material-ui/core';
import { I18nContext } from '../../I18n';
import apiToUrlMap from '../../ApiMapping';
import { TertiaryButton } from '../AtomComponents';
import { useGetDataService } from '../../hooks/useQueryCustomHooks/useDataService';
import { UseQueryResult } from '@tanstack/react-query';

function DailyReportDialog(props: any) {
  const { isDialogOpen, onDailyReportOk, closeDialog } = props;

  const I18n = React.useContext(I18nContext);

  const { data } = useGetDataService({
    url: apiToUrlMap.stockDailyReport,
    onSuccess: (isCheckedObj: any) => isCheckedObj['stockReportNotification'],
    placeholderData: false,
  }) as UseQueryResult<boolean>;

  const [isChecked, setIsChecked] = useState(data);

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title={I18n.dailyReport || 'Daily Report'}
      content={
        <div className="grid">
          <p className="cell small-12">
            {I18n.keepUpWithWhatsNewInStockList || 'Keep up with what’s new in the Stock List'}
          </p>
          <div className="cell grid-x align-middle">
            <Checkbox
              checked={isChecked}
              onChange={(e: any) => {
                setIsChecked(e.target.checked);
              }}
              color="primary"
              className="padding-left-0"
            />
            <label className="cell auto">
              {I18n.receiveDailySpreadSheet ||
                'Receive a daily spreadsheet report on all new inventory availability and price drops.'}
            </label>
          </div>
        </div>
      }
      actions={
        <TertiaryButton
          onClick={(e) => {
            const variables = { e, isChecked };
            onDailyReportOk({
              variables,
              url: apiToUrlMap.stockDailyReport,
              httpMethod: 'PUT',
              additionalParams: {
                body: {
                  stockReportNotification: isChecked,
                },
              },
            });
          }}
        >
          {I18n.ok || 'OK'}
        </TertiaryButton>
      }
    />
  );
}

export default DailyReportDialog;
